<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增点检保养项目"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="编号">
          <a-input
            v-decorator="[
              'number',
              {
                rules: [
                  { required: true, message: '请输入编号' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择类型' }],
              },
            ]"
            :allowClear="true"
            style="width: 100%"
          >
            <a-select-option value="inspection">设备点检</a-select-option>
            <a-select-option value="maintenance">设备保养</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否启用">
          <a-select v-decorator="['is_enabled', { initialValue: '1' }]" :allowClear="true" style="width: 100%">
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="项目内容">
          <a-textarea
            v-decorator="['content', { rules: [{ max: 512, message: '超出最大长度(512)' }] }]"
            :rows="3"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="标准">
          <a-textarea
            v-decorator="['standard', { rules: [{ max: 512, message: '超出最大长度(512)' }] }]"
            :rows="3"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
            :rows="3"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { maintenanceItemCreate, getMaintenanceItemNumber } from "@/api/equipment";

export default {
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          maintenanceItemCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
  watch: {
    visible(status) {
      if (status) {
        getMaintenanceItemNumber().then((data) => {
          this.dataForm.setFieldsValue(data);
        });
      }
    },
  },
};
</script>

<style scoped></style>
